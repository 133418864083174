import { Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatRow, MatTableDataSource } from '@angular/material/table';
import { SiteService } from '@app/app-state/site.service';
import { UserService } from '@app/app-state/user.service';
import * as backend from '@app/backend';
import { GenerateChartDataService } from '@app/backend/generate-chart-data.service';
import { DrillAndBlastCostsInterface } from '@app/models/chart-data.model';
import { dataSourceCollectionSchema, DataSourceSchema, DrillAndBlastCostsParams } from '@app/models/drill-and-blast-costs.model';
import { UnitConversionPipe } from '@app/pipes';
import { measurement } from '@lib/measurement';
import * as charts from '@utilities/highchart-objectify';
import * as Highcharts from 'highcharts/highstock';
import { combineLatest, debounceTime, Subject, Subscription } from 'rxjs';

@Component({
	selector: 'app-drill-and-blast-costs',
	templateUrl: './drill-and-blast-costs.component.html',
	styleUrls: ['./drill-and-blast-costs.component.scss']
})
export class DrillAndBlastCostsComponent implements OnInit {
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChildren(MatRow, { read: ElementRef }) rows!: QueryList<ElementRef<HTMLTableRowElement>>;

	public selectedMatTabIndex: number = 0;
	public loadingDrillAndBlastCosts: boolean;
	public currentDrillAndBlastCost: DrillAndBlastCostsParams;
	public drillAndBlastCosts: DrillAndBlastCostsParams[] = [];
	public dataSourceSchema: DataSourceSchema[] = dataSourceCollectionSchema;
	public displayedColumns: string[] = this.dataSourceSchema.map(col => col.key);
	public dataSource: MatTableDataSource<DrillAndBlastCostsParams>;
	public chartOptions = [];
	public Highcharts: typeof Highcharts = Highcharts; // required
	private domainId: number;
	private subdomainId: number;
	private siteId: number;
	private startDate: string;
	private endDate: string;
	private selectedTimeline: string;
	private subscriptions: Subscription = new Subscription();
	public siteDatasetLists: {id: number, name: string}[];
	private inputSubject: Subject<DrillAndBlastCostsParams> = new Subject();
	private selectedSiteName: string;
	public isNewRow: boolean = false;
	public costDetails;
	private totalBlastingCost;
	private annotationData;
	public userUnitMeasurement: string;


	constructor(
		private snackBar: MatSnackBar,
		private readonly siteService: SiteService,
		private generateChartDataService: GenerateChartDataService,
		private userService: UserService
	) {
		const combinedSub = combineLatest([
			siteService.selectedTimeline$,
			siteService.startDate$,
			siteService.endDate$,
			siteService.subDomainId$,
			siteService.siteId$
		])
		.pipe(debounceTime(100))
		.subscribe(ids => {			
			const [selectedTimeline, startDate, endDate, subDomainId, siteId] = ids;
			this.selectedTimeline = selectedTimeline;
			this.startDate = startDate;
			this.endDate = endDate;
			this.subdomainId = subDomainId;
			this.siteId = siteId;
			this.triggerLoadData(this.drillAndBlastCosts);
		});
		this.subscriptions.add(combinedSub);
		
		this.subscriptions.add(this.inputSubject.pipe(debounceTime(500)).subscribe(value => {
			this.updateInputField(value);
		}));

		this.subscriptions.add(this.userService.userUnitMeasurement$.subscribe((measurement: string) => {
			this.userUnitMeasurement = measurement;
		}));
	}

	ngOnInit() {
		// Read only Fields
		this.displayedColumns.unshift('datasetName');

		this.subscriptions.add(this.siteService.domainId$.subscribe(domainId => {
			this.domainId = domainId;
			if (this.domainId) {
				this.constructChartsAndPopulateTableView(this.domainId);
			}
		}));

		this.subscriptions.add(backend.loadingDrillAndBlastCostsData$.subscribe((loading)=>{
			this.loadingDrillAndBlastCosts = loading;
		}));
		
		this.subscriptions.add(backend.drillAndBlastCostsData$.subscribe((response)=>{
			this.drillAndBlastCosts = response
		}));

		this.subscriptions.add(this.siteService.seletedSiteName$.subscribe((siteName)=>{
			if (this.isNewRow) {
				this.drillAndBlastCosts.shift();
				this.currentDrillAndBlastCost = null;
				this.isNewRow = false;
			}
			this.selectedSiteName = siteName;
		}));
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe();
	}
	
	private constructChartsAndPopulateTableView(domainId: number) {
		this.subscriptions.add(backend.getCacheDrillAndBlastCostsByDomain(domainId).subscribe(async (response)=>{
			if (response) {
				this.drillAndBlastCosts = await response;
				this.triggerLoadData(this.drillAndBlastCosts);
			}
		}));
	}

	private constructDrillAndBlastCostCharts(drillAndBlastCosts:DrillAndBlastCostsParams[]) {
		const data: DrillAndBlastCostsInterface = this.generateChartDataService.generateChartData(drillAndBlastCosts);
		for (const key in data.drillAndBlastCostsGraphData) {
			this.chartOptions = charts.generateChartOptions(data.drillAndBlastCostsGraphData[key]);
		}
	}

	onFieldInputClick(event, col) {
		const target = event.target;
		target.readOnly = col.readonly;
	}

	async onFieldInputChange(element, key: string, value: string|number|Date) {
		if (!element.id) {
			const secondaryBreakageCost = this.costDetails?.secondaryBreakageCost?.cost || 0
			const powerConsumptionCost = this.costDetails?.crusherPowerConsumptionCost?.cost || 0
			if (key == 'secondaryBreakageQuantity' || key =='powerConsumptionUnit') {
				if (this.currentDrillAndBlastCost['secondaryBreakageQuantity'] || this.currentDrillAndBlastCost['powerConsumptionUnit']) {
					if (key == 'secondaryBreakageQuantity') {
						if (this.currentDrillAndBlastCost['secondaryBreakageQuantity']) {
							this.currentDrillAndBlastCost['secondaryBreakageCost'] = +( this.currentDrillAndBlastCost['secondaryBreakageQuantity'] ? (secondaryBreakageCost * this.currentDrillAndBlastCost['secondaryBreakageQuantity']) : 0);
							this.currentDrillAndBlastCost.totalBlastingCost = this.currentDrillAndBlastCost.drillingCostPerTon + this.currentDrillAndBlastCost.totalExplosiveCost + this.currentDrillAndBlastCost.secondaryBreakageCost;
							this.currentDrillAndBlastCost.totalCost = this.currentDrillAndBlastCost.totalExplosiveCost + this.currentDrillAndBlastCost.drillingCostPerTon + this.currentDrillAndBlastCost.secondaryBreakageCost + this.currentDrillAndBlastCost.powerConsumptionCost;
						}
					}
					if (key == 'powerConsumptionUnit') {
						if (this.currentDrillAndBlastCost['powerConsumptionUnit']) {
							this.currentDrillAndBlastCost['powerConsumptionCost'] = +( this.currentDrillAndBlastCost['powerConsumptionUnit'] ? (powerConsumptionCost * this.currentDrillAndBlastCost['powerConsumptionUnit']) : 0);
							this.currentDrillAndBlastCost.totalCost = this.currentDrillAndBlastCost.totalExplosiveCost + this.currentDrillAndBlastCost.drillingCostPerTon + this.currentDrillAndBlastCost.secondaryBreakageCost + this.currentDrillAndBlastCost.powerConsumptionCost;
						}
					}
				}
			}
		}
		else {
			if (key && value) {
				const drillAndBlastCost = this.drillAndBlastCosts.find(cost => cost.id == element.id);
				if (key == 'secondaryBreakageQuantity') {
					drillAndBlastCost['secondaryBreakageCost'] = +measurement.toMaxDecimals((drillAndBlastCost['secondaryBreakageCost'] * drillAndBlastCost['secondaryBreakageQuantity']),2);
					drillAndBlastCost['totalBlastingCost'] = +measurement.toMaxDecimals(
						(+(drillAndBlastCost['drillingCostPerTon']) +
						+(drillAndBlastCost['totalExplosiveCost']) +
						+(drillAndBlastCost['secondaryBreakageCost'])),2
					);
					drillAndBlastCost['totalCost'] = +measurement.toMaxDecimals(
						(+(drillAndBlastCost['totalExplosiveCost']) +
						+(drillAndBlastCost['drillingCostPerTon']) +
						+(drillAndBlastCost['secondaryBreakageCost']) +
						+(drillAndBlastCost['powerConsumptionCost'])),2
					);
				}
				if (key == 'powerConsumptionUnit') {
					drillAndBlastCost['powerConsumptionCost'] = +measurement.toMaxDecimals((drillAndBlastCost['powerConsumptionCost'] * drillAndBlastCost['powerConsumptionUnit']),2);
					drillAndBlastCost['totalCost'] = +measurement.toMaxDecimals(
						(+(drillAndBlastCost['totalExplosiveCost']) +
						+(drillAndBlastCost['drillingCostPerTon']) +
						+(drillAndBlastCost['secondaryBreakageCost']) +
						+(drillAndBlastCost['powerConsumptionCost'])),2
					);
				}
				if (drillAndBlastCost) {
					drillAndBlastCost[key] = value;
					this.inputSubject.next(drillAndBlastCost);
				}
			}
		}
	}

	private async updateInputField(updatedData: DrillAndBlastCostsParams) {
		const response = await backend.updateDrillAndBlastCosts(updatedData);
		if (response) {
			this.snackBar.open('Successfully saved', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000});
		}
	}

	public onSelectedMatTabChange(event) {
		if (event.index == 0) {
			if (this.isNewRow) {
				this.drillAndBlastCosts.shift();
				this.currentDrillAndBlastCost = null;
				this.isNewRow = false;
			}
			this.triggerLoadData(this.drillAndBlastCosts);
		}
	}

	public async initiateDrillAndBlastCosts() {
		if (this.currentDrillAndBlastCost) {
			this.snackBar.open('Please fill the current row Drill And Blast Costs', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000,});

			return;
		}

		if (!this.siteId) {
			this.snackBar.open('Please select Site prior adding Costs', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000,});

			return;
		}

		if (this.selectedMatTabIndex === 0) {
			this.selectedMatTabIndex = 1; 				// Change into table view
		}
		this.loadingDrillAndBlastCosts = true;

		this.siteDatasetLists = await backend.fetchSiteDatasetsList(this.siteId);
		this.costDetails = await backend.fetchCostDetail(this.siteId);
		this.isNewRow = true;
		
		this.currentDrillAndBlastCost = {
			explosivesCostPerTon: 0,
			explosivesCostPerTonGoal: 0,
			drillingCostPerTon: 0,
			drillingCostPerTonGoal: 0,
			drillAndBlastCostPerTon: 0,
			drillAndBlastCostPerTonGoal: 0,
			secondaryBreakageQuantity: 0,
			secondaryBreakageCost: 0,
			secondaryBreakageCostGoal: 0,
			recordedAt: new Date().toLocaleDateString(),
			datasetName: '',
			apiV2DatasetId: undefined,
			siteId: this.siteId,
			domainId: this.domainId,
			subdomainId: this.subdomainId,
			siteName: this.selectedSiteName,
			powerConsumptionUnit:0,
			powerConsumptionCost: 0,	
			powerConsumptionCostGoal: 0,
			totalCost: 0,
			totalBlastingCost: 0,
			totalBlastingCostGoal: 0,
			totalExplosiveCost: 0,
			totalExplosiveCostGoal: 0,
			totalCostGoal: 0
		};
		this.drillAndBlastCosts.unshift(this.currentDrillAndBlastCost);
		this.paginator.firstPage();
		this.scrollToFirstIndex();
		this.triggerLoadData(this.drillAndBlastCosts);
	}

	private scrollToFirstIndex(): void {
		let elem = this.rows.first;
		elem?.nativeElement.scrollIntoView({ block: 'center', behavior: 'smooth' });
	}

	public async onDatasetSelect(event) {
		const value = event.value;

		const isCostFoundPerProject = this.drillAndBlastCosts.find(cost => cost.apiV2DatasetId == value.id);
		if (isCostFoundPerProject) {
			this.snackBar.open('A Project should have only one Cost. Please select other Project and add Cost.', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });
			event.source.value = undefined;

			return;
		}

		this.currentDrillAndBlastCost.datasetName = value.name;
		this.currentDrillAndBlastCost.apiV2DatasetId = value.id;
		const response = await backend.dataAnnotationsGet(value.id, ['shot-report']);
		this.annotationData = response.data.dataset.annotations[0]?.data ? JSON.parse(response.data.dataset.annotations[0]?.data).params : [];
		this.updateData(this.annotationData);
	}

	private updateData(params) {
		const data:any = this.dataSource.data[0];
		const secondaryBreakageCost = +this.costDetails?.secondaryBreakageCost?.cost || 0;
		const powerConsumptionCost = +this.costDetails?.crusherPowerConsumptionCost?.cost || 0;
		data.powerConsumptionCost = +data['powerConsumptionUnit'] ? (powerConsumptionCost * data['powerConsumptionUnit']) : 0;
		data.secondaryBreakageCost = +data['secondaryBreakageQuantity'] ? (secondaryBreakageCost * data['secondaryBreakageQuantity']) : 0;
		data.explosivesCostPerTon = this.calculateCostForProducts(this.costDetails?.explosiveCost?.data, 'bulkExplosives', params);
		const boosterCostTotal = +this.calculateCostForProducts(this.costDetails?.boosterCost?.data, 'boosters', params);
		const detonatorCostTotal = +this.calculateCostForProducts(this.costDetails?.detonatorCost?.data, 'detonators', params);
		const drillingLengthValueUnit = 'distance';
		const drillingCost = +(new UnitConversionPipe()).transform(+this.costDetails?.drillingCost?.cost, this.userUnitMeasurement, drillingLengthValueUnit, this.userUnitMeasurement == 'metric');
		const drillingLength = +((new UnitConversionPipe()).transform(+params[0]?.totalDrillLength, this.userUnitMeasurement, drillingLengthValueUnit) || 0);
		data.drillingCostPerTon = +(drillingCost * drillingLength) || 0;
		data.totalExplosiveCost = +(boosterCostTotal + detonatorCostTotal + data.explosivesCostPerTon || 0);
		data.drillAndBlastCostPerTon = +(data.drillingCostPerTon + data.totalExplosiveCost || 0);
		data.totalCost = +(data.totalExplosiveCost + data.drillingCostPerTon + data.secondaryBreakageCost + data.powerConsumptionCost || 0);
		data.totalBlastingCost = +data.drillingCostPerTon + data.totalExplosiveCost + data.secondaryBreakageCost;
		for(let key in data){
			if (key !== "datasetName" && key !== "siteName" && key !== "recordedAt") {
				data[key] = +measurement.toMaxDecimals(data[key], 2);
			}
		}
	}
	

	private calculateCostForProducts(data, type, params) {
		const valueUnit = 'weight';
		return data?.reduce((sum, item) => {
			const productData = params[0]?.productsUsage?.params[0][type]?.find(product =>
				product.product.params[0]?.name === item.product && product.product.params[0]?.brand === item.brand);
			const multiplier = type === 'detonators' ? productData?.count : (new UnitConversionPipe()).transform(+productData?.mass, this.userUnitMeasurement, valueUnit, this.userUnitMeasurement == 'metric');
			const convertedCost = type === 'detonators' ? item?.cost : (new UnitConversionPipe()).transform(+item?.cost, this.userUnitMeasurement, valueUnit, this.userUnitMeasurement == 'imperial');
			return sum + (convertedCost * (multiplier || 0));
		}, 0) || 0;
	}

	public async addDrillAndBlastCosts() {
		if (this.currentDrillAndBlastCost && !this.currentDrillAndBlastCost.apiV2DatasetId) {
			this.snackBar.open('Please select a Project and add Cost.', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });

			return;
		}

		const response = await backend.createDrillAndBlastCosts(this.currentDrillAndBlastCost);
		if (response) {
			this.snackBar.open('Successfully saved', ' ', { horizontalPosition: 'right', verticalPosition: 'top', duration: 3 * 1000 });

			if (response.id) {
				this.currentDrillAndBlastCost.id = response.id;
				this.drillAndBlastCosts = [...this.drillAndBlastCosts];

				this.isNewRow = false;
				this.currentDrillAndBlastCost = undefined;
				backend.drillAndBlastCostsData$.next(this.drillAndBlastCosts);
			}
		}
	}

	triggerLoadData(originalData){
		this.loadingDrillAndBlastCosts = true;
		const filterData = this.generateChartDataService.getFilteredData(
			originalData,
			this.domainId,
			this.subdomainId,
			this.siteId,
			this.startDate,
			this.endDate,
			this.selectedTimeline    
		)
		this.dataSource = new MatTableDataSource(filterData);
		
		// Ensure paginator is set
		if (!this.dataSource.paginator) {
			this.dataSource.paginator = this.paginator;
		}

		// Update chart and loading status
		if (filterData) {	
			this.constructDrillAndBlastCostCharts(filterData);
		}
		this.loadingDrillAndBlastCosts = false;
	}
}
