export const DrillAndBlastDataStructure = {
	loadingDesign: {
		loadingDesign: [
			{ dataField: 'totalExplosiveWeight', chartType: 'column', chartTitle: 'Explosive Quantity', yAxisLabel: 'Total Explosive', xAxisLabel: 'Project Names', isExplosiveGraph: true, valueUnit: 'largeWeight' },
			{ dataField: '', chartType: 'column', chartTitle: 'Truck Explosive', dataFieldTwo: '', seriesType: 'column', isTruckGraph: true, yAxisLabel: 'Truck Explosive', xAxisLabel: 'Truck Details', valueUnit: 'largeWeight' },
			{ dataField: 'averageExplosive', chartType: 'line', chartTitle: 'Average Explosive', isAverage: true, yAxisLabel: 'Average Explosive', xAxisLabel: 'Project Names', valueUnit: 'weight' },
			{ dataField: 'averageBurdenRelief', chartType: 'line', chartTitle: 'Average Burden Relief', isAverage: true, yAxisLabel: 'Average Burden Relief', xAxisLabel: 'Project Names', valueUnit: 'smallTimePerDistance'}
		]
	},
	burdenAndSpacing: {
		burdenSpacinGraph: [
			{ dataField: 'averageSpacing', chartType: 'column', chartTitle: 'Average Spacing', isAverage: true, yAxisLabel: 'Average Spacing', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'averageFaceBurden', chartType: 'column', chartTitle: 'Average Face Burden', isAverage: true, yAxisLabel: 'Average Face Burden', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'averagePatternBurden', chartType: 'line', chartTitle: 'Average Pattern Burden', isAverage: true, dataFieldTwo:'designBurden', seriesType:'line', seriesType1:'line', yAxisLabel: 'Average Pattern Burden', xAxisLabel: 'Project Names', valueUnit: 'distance', isCombineType:true, isDesignBurdenGraph:true },
		]
	},
	drillLengthAndDiameter: {
		drillDiameterGraph: [
			{ dataField: 'totalDrillLength', chartType: 'line', chartTitle: 'Total Drilling Length', yAxisLabel: 'Total Drilling Length', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'averageHoleDiameter', chartType: 'column', chartTitle: 'Average Hole Diameter', isAverage: true, yAxisLabel: 'Hole Diameter', xAxisLabel: 'Project Names', valueUnit: 'smallDistance' },
		]
	},
	holeCountAndLength: {
		holeCountGraph: [
			{ dataField: 'holeCount', chartType: 'column', chartTitle: 'Number Of Holes', yAxisLabel: 'Hole Count', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'averageHoleLength', chartType: 'line', chartTitle: 'Average Hole Length',  isAverage: true, yAxisLabel: 'Average Hole Length', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: '', chartType: 'column', chartTitle: 'Average Laborers, Average Hours', isAverage: true, dataFieldTwo: '', seriesType: 'column', seriesType1: 'column', isCombineType: true, isLabourGraph: true, yAxisLabel: 'Average Laborers, Average Hours', xAxisLabel: 'Site Names', valueUnit: 'count' },
		],
	},
	pfAndTonData: {
		pfTonGraph: [
			{ dataField: 'blastVolume', chartType: 'column', chartTitle: 'Blast Volume', yAxisLabel: 'Blast Volume', xAxisLabel: 'Project Names', valueUnit: 'volume' },
			{ dataField: 'blastTonnage', chartType: 'column', chartTitle: 'Blast Tonnage', yAxisLabel: 'Blast Tonnage', xAxisLabel: 'Project Names', valueUnit: 'largeWeight' },
			{ dataField: 'powderFactorVolume', chartType: 'column', chartTitle: 'Powder Factor Volume', isAverage: true, yAxisLabel: 'Powder Factor Volume', xAxisLabel: 'Project Names', valueUnit: 'powderFactorVolume' },
			{ dataField: 'powderFactorWeight', chartType: 'column', chartTitle: 'Powder Factor Weight', isAverage: true, yAxisLabel: 'Powder Factor Weight', xAxisLabel: 'Project Names', valueUnit: 'powderFactorWeight' },
			{ dataField: 'drillYieldTonnage', chartType: 'column', chartTitle: 'Drill Yield/specific drilling', isAverage: true, yAxisLabel: 'Drill Yield/specific drilling', xAxisLabel: 'Project Names', valueUnit: 'volume' },
			{ dataField: 'drillYieldVolume', chartType: 'column', chartTitle: 'Drill Yield/specific drilling', isAverage: true, yAxisLabel: 'Drill Yield/specific drilling', xAxisLabel: 'Project Names', valueUnit: 'volume' },
		]
	},
};

export const FielderAnalysisDataStructure = {
	fielderAnalyticsData: {
		fielderAnalyticsDataGraph: [
			{ dataField: 'totalDrilledHoles', chartType: 'column', chartTitle: 'Total Drilled Holes', yAxisLabel: 'Total Drilled Holes', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilled Length', yAxisLabel: 'Total Drilled Length', xAxisLabel: 'Project Names', valueUnit: 'distance' },
			{ dataField: 'totalDrillingTime', chartType: 'line', chartTitle: 'Total Drilling Time', yAxisLabel: 'Drilling Time', xAxisLabel: 'Project Names', valueUnit: 'hour' },
			{ dataField: 'averageTimeBetweenHoles', chartType: 'column', chartTitle: 'Average Time Between Holes', isAverage: true, yAxisLabel: 'Average Time Between Holes', xAxisLabel: 'Project Names', valueUnit: 'minute' },
			{ dataField: 'averageTimePerHole', chartType: 'line', chartTitle: 'Average Time Per Hole', isAverage: true, yAxisLabel: 'Average Time Per Hole', xAxisLabel: 'Project Names', valueUnit: 'minute' },
			{ dataField: 'averagePerDayDrill', chartType: 'column', chartTitle: 'Average Drilled Per Day', isAverage: true, yAxisLabel: 'Average Per Day Drill', xAxisLabel: 'Project Names', valueUnit: 'distance' },
		]
	},
	utilizationByShot: {
		utilizationByShotGraph: [
			{ dataField: 'utilization', chartType: 'column', chartTitle: 'Utilization', yAxisLabel: 'Utilization', xAxisLabel: 'Project Names', valueUnit: 'percent' },
		],
	},
	drilledHolesByDrilledLength: {
		drilledHolesByDrilledLengthGraph: [
			{ dataField: 'totalHoles', chartType: 'zoomType', chartTitle: 'Total Holes, Total Drilled Holes', yAxisLabel: 'Total Holes, Total Drilled Holes', xAxisLabel: 'Project Names', dataFieldTwo: 'totalDrilledHoles', seriesType: 'line', seriesType1: 'column', isCombineType: true, valueUnit: 'count' },
		],
	},
	totalHolesAndTotalDrilledHolesByShot: {
		totalHolesAndTotalDrilledHolesByShotGraph: [
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilled Holes, Total Drilled Length', yAxisLabel: 'Total Drilled Holes, Total Drilled Length', xAxisLabel: 'Project Names', dataFieldTwo: 'totalDrilledHoles', isCombineType: true, valueUnit: 'count' },
		]
	},
	drillGraph: {
		drillGraphData: [
			{ dataField: 'totalDrilledHoles', chartType: 'column', chartTitle: 'Total Drilled Holes By Drill Machine Name', yAxisLabel: 'Total Drilled Holes', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit: 'count' },
			{ dataField: 'totalDrilledLength', chartType: 'line', chartTitle: 'Total Drilling Length By Drill Machine Name', yAxisLabel: 'Total Drilled Length', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit: 'distance' },
			{ dataField: 'totalDrillingTime', chartType: 'line', chartTitle: 'Total Drilling Time By Drill Machine Name', yAxisLabel: 'Total Drilled Time', xAxisLabel: 'Drill Machine Name', isDrillName: true, valueUnit: 'hour' },
		]
	}
};

export const FragmentationStructure = {
	fragmentationGraphData: {
		fragmentationGraph: [
			{ dataField: 'd50', chartType: 'column', chartTitle: 'D50, D80', isAverage: true, dataFieldTwo: 'd80', seriesType: 'column', seriesType1: 'column', isCombineType: true, yAxisLabel: 'D50, D80', xAxisLabel: 'Project Names', valueUnit: 'smallDistance' },
			{ dataField: 'fragmentationSizeRange', chartType: 'column', chartTitle: 'Fragmentation Size Ranges Chart', isSizeRange: true, isAverage: true, yAxisLabel: '%passing', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'tonsPerHour', chartType: 'column', chartTitle: 'Tons per Hour', dataFieldTwo: 'tonsPerHourGoal', seriesType: 'column', seriesType1: 'column', isCombineType: true, yAxisLabel: 'Tons per Hour', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'finesPercent', chartType: 'line', chartTitle: 'Fines Percent', isAverage: true, isFinesPercent: true, yAxisLabel: 'Fines Percent', xAxisLabel: 'Project Names',  valueUnit: 'count' },
			{ dataField: 'oversizePercent', chartType: 'column', chartTitle: 'Oversize Percent', isSizeRange: true, isAverage: true, isOversizePercent: true, yAxisLabel: 'Oversize Percent', xAxisLabel: 'Project Names',  valueUnit: 'count' },
		]
	},
	muckpile: {
		muckpile: [
			{ dataField: 'swellFactor', chartType: 'line', chartTitle: 'Swell Factor', isAverage: true, yAxisLabel: 'Swell Factor', xAxisLabel: 'Project Names',  valueUnit: 'count' },
			{ dataField: 'averageMuckpileVolume', chartType: 'line', chartTitle: 'Muckpile Volume', isAverage: true, yAxisLabel: 'Muckpile Volume', xAxisLabel: 'Project Names', valueUnit:  'volume' },
			{ dataField: 'muckpileTonnage', chartType: 'line', chartTitle: 'Muckpile Tonnage', isAverage: true, yAxisLabel: 'Muckpile Tonnage', xAxisLabel: 'Project Names', valueUnit:  'largeWeight' },
		]
	}
};

export const DrillAndBlastCostsStructure = {
	drillAndBlastCostsGraphData: {
		drillAndBlastCostsGraph: [
			{ dataField: 'explosivesCostPerTon', chartType: 'column', chartTitle: 'Explosive Cost', dataFieldTwo: 'explosivesCostPerTonGoal', seriesType: 'column', seriesType1: 'column', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Explosive Cost', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'drillingCostPerTon', chartType: 'column', chartTitle: 'Total Drilling Cost', dataFieldTwo: 'drillingCostPerTonGoal', seriesType: 'column', seriesType1: 'column', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Total Drilling Cost', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'totalBlastingCost', chartType: 'column', chartTitle: 'Total Blasting Cost', dataFieldTwo: 'totalBlastingCostGoal', seriesType: 'column', seriesType1: 'column', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Total Blasting Cost', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'secondaryBreakageCost', chartType: 'area', chartTitle: 'Secondary Breakage Cost', dataFieldTwo: 'secondaryBreakageCostGoal', seriesType: 'area', seriesType1: 'area', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Secondary Breakage Cost', xAxisLabel: 'Project Names', valueUnit: 'count' },
			{ dataField: 'powerConsumptionCost', chartType: 'area', chartTitle: 'Cusher Power Consumption Cost', dataFieldTwo: 'powerConsumptionCostGoal', seriesType: 'area', seriesType1: 'area', isCombineType: true, isDrillAndBlastCosts: true, yAxisLabel: 'Crusher Power Consumption Cost', xAxisLabel: 'Project Names', valueUnit: 'count' },
		]
	}
};
