import {ChangeDetectionStrategy,ChangeDetectorRef,Component,Input,Optional} from '@angular/core';
import {measurement} from "../../measurement";
import {BehaviorSubject,combineLatest,op} from '../../rxjs';
import {MeasurementTypeToUnit} from './measurement-type-to-unit';

@Component({
	selector: 'measurement-unit',
	templateUrl: './measurement-unit.component.html',
	styleUrls: ['./measurement-unit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeasurementUnitComponent{
	constructor(
		@Optional() private readonly measurementTypeToUnit:MeasurementTypeToUnit,
		private readonly cdr:ChangeDetectorRef,
	){
	}

	@Input() 
		get type(){
			return this.type$.value;
		}
		set type(v){
			this.type$.next(v);
		}

	private readonly type$=new BehaviorSubject<string>('count');

	public readonly contents$=
		combineLatest([
			this.measurementTypeToUnit.units$,
			this.type$])
		.pipe(
			op.debounceTime(0),
			op.map(([typeToUnit,type])=>{
				const types=(type ?? 'count').split('/');
				const valueUnits=types.map(type=>(measurement.typeMeta[<measurement.Type>type] ?? measurement.typeMeta.count).valueUnit);
				const units=types.map(type=>(typeToUnit?.[<measurement.Type>type]) ?? 'none');
				return units.map(unit=>measurement.abbreviation(unit)).join('/');
				//this.cdr.detectChanges();
			}));
}
