export * from './online-observable';
export * from './size-observable';
export * from './css';
import * as css from './css';
export { css };

export function getCssScale(ele:HTMLElement){
	if(ele){
		let transform=ele.style.transform;
		if(transform.startsWith('matrix(')){
			transform=transform.substring(7,transform.length-1);
			const m=transform.split(',').map(v=>+v);
			if(m.length===6){
				const v=Math.SQRT1_2;
				return 1/Math.sqrt((m[0]*v+m[1]*v)**2+(m[2]*v+m[3]*v)**2);
			}
		}
	}
	return 1;
}
