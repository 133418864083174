<mat-tab-group
  headerPosition="below"
  [(selectedIndex)]="selectedMatTabIndex"
  (selectedTabChange)="onSelectedMatTabChange($event)"
>
	<mat-tab label="Fragmentation Chart View">
		<div class="content-container">
			<ng-container *ngIf="!loading">
				<div class="highchart-wrapper">
					<mat-grid-list cols="2" rowHeight="2:1">
					<mat-grid-tile *ngFor="let chartOption of chartOptions">
						<highcharts-chart
						[Highcharts]="Highcharts"
						[options]="chartOption">
						</highcharts-chart>
					</mat-grid-tile>
					</mat-grid-list>
				</div>
			</ng-container>
			<ng-container *ngIf="loading">
				<div class="loading-logo">
					<mat-spinner></mat-spinner>
				</div>
			</ng-container>
		</div>
	</mat-tab>

	<mat-tab label="Fragmentation Table View">
		<div class="header-container">
			<button *ngIf="siteId" mat-raised-button color="primary" (click)="addFinesGoalValues()" [disabled]="!dataSource?.data?.length">Add Goals</button>
		</div>
		<div class="content-container">
			<ng-container  *ngIf="!loading && siteId;">
				<table mat-table [dataSource]="dataSource">
					<!-- Project Column -->
					<ng-container matColumnDef="project">
						<th mat-header-cell *matHeaderCellDef> Project </th>
						<td mat-cell *matCellDef="let element"> {{element.project}} </td>
					</ng-container>
					
					<!-- Fine Column -->
					<ng-container matColumnDef="fines">
						<th mat-header-cell *matHeaderCellDef> Fines </th>
						<td mat-cell *matCellDef="let element"> {{element.fines}} </td>
					</ng-container>
					
					<!-- Fines Goal Column -->
					<ng-container matColumnDef="finesGoal">
						<th mat-header-cell *matHeaderCellDef> Fines Goal (%)</th>
						<td mat-cell *matCellDef="let element; let i = index">
							<input matInput [(ngModel)]="element.finesGoal" type="number" (change)="onInputChange()">
						</td>
					</ng-container>
					
					<ng-container matColumnDef="oversizeGoal">
						<th mat-header-cell *matHeaderCellDef> Oversize Goal (%)</th>
						<td mat-cell *matCellDef="let element; let i = index">
							<input matInput [(ngModel)]="element.oversizeGoal" type="number" (change)="onInputChange()">
						</td>
					</ng-container>
								
					<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
					</table>
					
					<mat-paginator
						pageSize="30"
						[pageSizeOptions]="[30, 40]"
						hidePageSize="true"
					>
					</mat-paginator>	
			</ng-container>

		<ng-container *ngIf="!loading && !siteId">
			<span>Please select site prior to add fine goals.</span>
		</ng-container>

		<ng-container *ngIf="loading || tableLoading">
			<div class="loading-logo">
				<mat-spinner></mat-spinner>
			</div>
		</ng-container>

		</div>
	</mat-tab>
</mat-tab-group>
