<div class="header-container" *ngIf="siteId">
	<button mat-raised-button color="primary" (click)="addFinesGoalValues()" [disabled]="!dataSource?.data?.length || tableLoading">Add Goals</button>
</div>
<div class="content-container">
	<ng-container  *ngIf="!loading && siteId;">
		<table mat-table [dataSource]="dataSource">
			<!-- Project Column -->
			<ng-container matColumnDef="project">
				<th mat-header-cell *matHeaderCellDef> Project </th>
				<td mat-cell *matCellDef="let element"> {{element.project}} </td>
			</ng-container>
			
			<!-- Fine Column -->
			<ng-container matColumnDef="fines">
				<th mat-header-cell *matHeaderCellDef> Fines </th>
				<td mat-cell *matCellDef="let element"> {{element.fines}} </td>
			</ng-container>
			
			<!-- Fines Goal Column -->
			<ng-container matColumnDef="finesGoal">
				<th mat-header-cell *matHeaderCellDef> Fines Goal (%)</th>
				<td mat-cell *matCellDef="let element">
					<input matInput [(ngModel)]="element.finesGoal" type="number" min="0" (change)="onInputChange()">
				</td>
			</ng-container>
			
			<!-- Oversize Column -->
			<ng-container matColumnDef="oversize">
				<th mat-header-cell *matHeaderCellDef> Oversize (%)</th>
				<td mat-cell *matCellDef="let element"> {{element.oversize}} </td>
			</ng-container>

			<!-- Oversize Goal Column -->
			<ng-container matColumnDef="oversizeGoal">
				<th mat-header-cell *matHeaderCellDef> Oversize Goal (%)</th>
				<td mat-cell *matCellDef="let element">
					<input matInput [(ngModel)]="element.oversizeGoal" type="number" min="0" (change)="onInputChange()">
				</td>
			</ng-container>

			<!-- Tons Per Hour Column -->
			<ng-container matColumnDef="tonsPerHour">
				<th mat-header-cell *matHeaderCellDef> Tons Per Hour</th>
				<td mat-cell *matCellDef="let element">
					<input matInput [(ngModel)]="element.tonsPerHour" type="number" min="0" (change)="onInputChange()">
				</td>
			</ng-container>

			<!-- Tons Per Hour Goal Column -->
			<ng-container matColumnDef="tonsPerHourGoal">
				<th mat-header-cell *matHeaderCellDef> Tons Per Hour Goal</th>
				<td mat-cell *matCellDef="let element">
					<input matInput [(ngModel)]="element.tonsPerHourGoal" type="number" min="0" (change)="onInputChange()">
				</td>
			</ng-container>
						
			<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
			<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
		
		</table>
			
		<mat-paginator
			pageSize="30"
			[pageSizeOptions]="[30, 40]"
			hidePageSize="true"
		>
		</mat-paginator>	
	</ng-container>

	<ng-container *ngIf="!loading && !siteId">
		<span>Please select site prior to add fine goals.</span>
	</ng-container>

	<ng-container *ngIf="loading || tableLoading">
		<div class="loading-logo">
			<mat-spinner></mat-spinner>
		</div>
	</ng-container>
</div>