<mat-tab-group headerPosition="below" [(selectedIndex)]="selectedMatTabIndex" (selectedTabChange)="onSelectedMatTabChange($event)">
	<mat-tab label="Drill & Blast Costs Chart View">
		<div class="content-container">
			<div *ngIf="drillAndBlastCosts?.length === 0" class="no-data">
				<span>No Drill & Blast Cost found for this selection. </span>
				<button mat-raised-button color="primary" (click)="initiateDrillAndBlastCosts()">Click</button> to add
			</div>

			<div class="highchart-wrapper">
				<mat-grid-list cols="2" rowHeight="2:1">
					<mat-grid-tile *ngFor="let chartOption of chartOptions; let i = index">
						<highcharts-chart
							id="chart-container-{{ i }}"
							[Highcharts]="Highcharts"
							[options]="chartOption">
						</highcharts-chart>
					</mat-grid-tile>
				</mat-grid-list>
			</div>
		</div>
	</mat-tab>

	<mat-tab label="Drill & Blast Costs Table View">
		<div class="header-container">
			<button mat-raised-button color="primary" (click)="initiateDrillAndBlastCosts()">+ Drill & Blast Cost</button>

			<button *ngIf="isNewRow" mat-raised-button color="primary" (click)="addDrillAndBlastCosts()">Submit</button>
		</div>


		<div class="content-container">
			<div class="table-content">
				<table mat-table [dataSource]="dataSource">
					<ng-container matColumnDef="datasetName">
						<th mat-header-cell *matHeaderCellDef>Project</th>
						<td mat-cell *matCellDef="let element">
							<ng-container *ngIf="!element.id; else displayColumn">
								<mat-select (selectionChange)="onDatasetSelect($event)" class="select-project">
									<mat-option *ngFor="let dataset of siteDatasetLists" [value]="dataset">{{dataset.name}}</mat-option>
								</mat-select>
							</ng-container>
	
							<ng-template #displayColumn>
								<span class="select-project">{{element.datasetName}}</span>
							</ng-template>
						</td>
					</ng-container>
	
					<ng-container [matColumnDef]="col.key" *ngFor="let col of dataSourceSchema">
						<th mat-header-cell *matHeaderCellDef>
							{{col.label}}
						</th>
	
						<td mat-cell *matCellDef="let element">
							<input [type]="col.type" matInput [title]="element[col.key]" [(ngModel)]="element[col.key]" readonly="true" (click)="onFieldInputClick($event, col)" (ngModelChange)="onFieldInputChange(element, col.key, $event)" />
						</td>
					</ng-container>
	
					<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
	
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
			</div>

			<mat-paginator
				pageSize="30"
				[pageSizeOptions]="[30, 40]"
				hidePageSize="true"
			>
			</mat-paginator>
		</div>
	</mat-tab>
</mat-tab-group>

<div class="loading-container" *ngIf="loadingDrillAndBlastCosts">
	<mat-spinner></mat-spinner>
</div>