import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { HighchartsChartModule } from 'highcharts-angular';
import { DashboardComponent } from '@app/gui/dashboard/dashboard.component';
import { HeaderComponent } from '@app/gui/components/header/header.component';
import { LoginComponent } from '@app/gui/login/login.component';
import { AvatarComponent } from '@app/gui/components/avatar/avatar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SitesDashboardComponent } from './modules/sites-dashboard/sites-dashboard.component';
import { DrillAndBlastDashboardComponent } from './modules/drill-and-blast-dashboard/drill-and-blast-dashboard.component';
import { FragmentationDashboardComponent } from './modules/fragmentation-dashboard/fragmentation-dashboard.component';
import { FielderDashboardComponent } from './modules/fielder-dashboard/fielder-dashboard.component';
import { HoleCountAndLengthComponent } from './modules/drill-and-blast-dashboard/hole-count-and-length/hole-count-and-length.component';
import { DrillLengthAndDiameterComponent } from './modules/drill-and-blast-dashboard/drill-length-and-diameter/drill-length-and-diameter.component';
import { BurdenAndSpacingComponent } from './modules/drill-and-blast-dashboard/burden-and-spacing/burden-and-spacing.component';
import { PfAndTonsComponent } from './modules/fragmentation-dashboard/pf-and-tons/pf-and-tons.component';
import { DataCollectionViewComponent } from './modules/data-collection-view/data-collection-view.component';
import { DrillAndBlastCostsComponent } from './modules/drill-and-blast-costs/drill-and-blast-costs.component';
import { FielderAnalyticsComponent } from './modules/fielder-dashboard/fielder-analytics/fielder-analytics.component';
import { DrilledHolesByDrilledLengthComponent } from './modules/fielder-dashboard/drilled-holes-by-drilled-length/drilled-holes-by-drilled-length.component';
import { FielderAnalyticsByDrillComponent } from './modules/fielder-dashboard/fielder-analytics-by-drill/fielder-analytics-by-drill.component';
import { HolesNDrilledHolesComponent } from './modules/fielder-dashboard/holes-n-drilled-holes/holes-n-drilled-holes.component';
import { UtilizationOnShotComponent } from './modules/fielder-dashboard/utilization-on-shot/utilization-on-shot.component';
import { UnitSettingsDialog } from '@app/gui/components/unit-settings-dialog/unit-settings-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DrillDataCollectionViewComponent } from './modules/data-collection-view/drill-data-collection-view/drill-data-collection-view.component';
import { DrillShiftDetailsCollectionViewComponent } from './modules/data-collection-view/drill-shift-details-collection-view/drill-shift-details-collection-view.component';
import { DatePipe } from '@angular/common';
import { UnitDisplayPipe, UnitConversionPipe } from '@app/pipes';
import { BurdenAndSpacingTableComponent } from './modules/drill-and-blast-dashboard/burden-and-spacing-table/burden-and-spacing-table.component';
import { UserService } from './app-state/user.service';
import { OperationalDataCollectionComponent } from './modules/operational-data-collection/operational-data-collection.component';
import * as Highcharts from 'highcharts/highstock';
import exportingModule from 'highcharts/modules/exporting';
import exportingDataModule from 'highcharts/modules/export-data';
import { LoadingDesignComponent } from './modules/drill-and-blast-dashboard/loading-design/loading-design.component';
import { FragmentationComponent } from './modules/fragmentation-dashboard/fragmentation/fragmentation.component';
import { MuckpileComponent } from './modules/fragmentation-dashboard/muckpile/muckpile.component';
import { FragmentationTableViewComponent } from './modules/fragmentation-dashboard/fragmentation-table-view/fragmentation-table-view.component';
import { CostingDetailsComponent } from './gui/components/costing-details/costing-details.component';
import { KeyValueNoSortPipe } from './pipes/key-value-no-sort.pipe';
import { MeasurementUnitComponent } from '@lib/angular/measurement/measurement-unit.component';

// Initialize the exporting module (it is not included by default)
exportingModule(Highcharts);
exportingDataModule(Highcharts);

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    LoginComponent,
    AvatarComponent,
    SitesDashboardComponent,
    DrillAndBlastDashboardComponent,
    FragmentationDashboardComponent,
    FielderDashboardComponent,
    HoleCountAndLengthComponent,
    DrillLengthAndDiameterComponent,
    BurdenAndSpacingComponent,
    PfAndTonsComponent,
    DataCollectionViewComponent,
    DrillAndBlastCostsComponent,
    FielderAnalyticsComponent,
    DrilledHolesByDrilledLengthComponent,
    FielderAnalyticsByDrillComponent,
    HolesNDrilledHolesComponent,
    UtilizationOnShotComponent,
    UnitSettingsDialog,
    DrillDataCollectionViewComponent,
    DrillShiftDetailsCollectionViewComponent,
    BurdenAndSpacingTableComponent,
    OperationalDataCollectionComponent,
    LoadingDesignComponent,
    FragmentationComponent,
    MuckpileComponent,
    FragmentationTableViewComponent,
    CostingDetailsComponent,
    KeyValueNoSortPipe,
    MeasurementUnitComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatSidenavModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatSelectModule,
    MatDialogModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatProgressSpinnerModule,
    PowerBIEmbedModule,
    ReactiveFormsModule,
    MatInputModule,
    HighchartsChartModule,
    MatTabsModule,
    MatGridListModule,
    MatTableModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatAutocompleteModule,
    MatSlideToggleModule,
    UnitDisplayPipe,
    UnitConversionPipe
  ],
  providers: [DatePipe, UnitDisplayPipe, UnitConversionPipe, ...UserService.providers,],
  bootstrap: [AppComponent]
})
export class AppModule { }
