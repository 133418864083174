import { environment } from "./environment.base";
export { environment };

environment.name = 'gourav';
environment.parentUrl =  'https://gourav.strayos.com';
environment.baseUrl = 'https://gouravdashboards.strayos.com';
environment.apiUrl = 'https://devx.strayos.com:3034';
environment.powerbiEmbedTokenUrl='https://powerbi-embed-token.azurewebsites.net/api/GetEmbedToken';
environment.pbWorkspaceId='4a1369d6-6433-42a6-b7a7-a554e2942fae';
environment.drillAndBlastDashboardReportId='278b5e5e-ad98-4b13-8ad0-66dd36a184eb';
environment.fielderDashboardReportId='23e0ae18-3245-46c8-8c72-3d1979b396e4';
environment.fragmentationDashboardReportId='761953f3-42e6-4507-adc4-d3bb2209a43d';
