<mat-tab-group headerPosition="below" [selectedIndex]="1">
	<mat-tab label="Operational Data Collection Chart View">
	</mat-tab>

	<mat-tab label="Operational Data Collection Table View">
		<div class="header-container">
			<mat-form-field appearance="outline" class="search-field">
				<mat-label>Search</mat-label>
				<input matInput #searchInput (keyup)="applyFilter(searchInput.value)">
			</mat-form-field>
			
			<div class="operational-type">
				<mat-button-toggle-group [(value)]="selectedType" (change)="selectType($event)">
					<mat-button-toggle  value="input">Input</mat-button-toggle>
					<mat-button-toggle value="output">Output</mat-button-toggle>
				</mat-button-toggle-group>
			</div>
		</div>

		<div class="content-container">
			<div class="table-content">
				<table class="data" mat-table [dataSource]="dataSource">
					<ng-container matColumnDef="datasetName">
						<th mat-header-cell *matHeaderCellDef>Project</th>
						<td mat-cell *matCellDef="let element">
							<ng-container *ngIf="siteDatasetLists?.length > 0 && selectedType == 'input' && element.isNewRow; else displayColumn">
								<mat-select (selectionChange)="onDatasetSelect($event)" class="select-project">
									<mat-option *ngFor="let dataset of siteDatasetLists" [value]="dataset">{{dataset.name}}</mat-option>
								</mat-select>
							</ng-container>
	
							<ng-template #displayColumn>
								<span class="select-project">{{element.datasetName}}</span>
							</ng-template>
						</td>
					</ng-container>
	
					<ng-container [matColumnDef]="col.key" *ngFor="let col of dataSourceSchema">
						<th mat-header-cell *matHeaderCellDef>
							{{col.label | UnitDisplayPipe: userUnitMeasurement : col.valueUnit}}
						</th>
	
						<td mat-cell *matCellDef="let element">
							<input [type]="col.type" matInput [ngModel]="element[col.key] | UnitConversionPipe: userUnitMeasurement : col.valueUnit " readonly="true" (click)="onFieldInputClick($event,col.readonly)" (ngModelChange)="onFieldInputChange(element, col.key, $event)" />
						</td>
					</ng-container>
	
					<tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
	
					<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
				</table>
			</div>
			
			
			<div class="actions-container">
				<div class="actions-button">
					<ng-container *ngIf="selectedType == 'input'">
						<button
							*ngIf="siteId"
							mat-raised-button
							color="primary"
							(click)="onSaveOrAddRow()"
						>
							{{ isNewRow ? "Save" : "Add Row" }}
						</button>
						<button
							class="btn-remove"
							mat-raised-button
							color="primary"
							*ngIf="isNewRow"
							(click)="removeRow()"
						>
							Remove
						</button>
					</ng-container>
				</div>
				<mat-paginator
					pageSize="30"
					[pageSizeOptions]="[30, 40]"
					hidePageSize="true"
				>
				</mat-paginator>
			</div>
		</div>
	</mat-tab>
</mat-tab-group>

<div class="loading-container" *ngIf="loadingOperationalInputDataCollection || loadingOperationalOutputDataCollection">
	<mat-spinner></mat-spinner>
</div>